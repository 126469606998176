<template>
  <div>
    <b-card title="">
      <!-- <b-row class="justify-content-start d-flex">
      <b-tabs content-class="mt-3">
        <b-tab
          title="Ativos"
          @click="(taxesList = false), getAllTaxes()"
          active
        >
        </b-tab>
        <b-tab title="Inativos" @click="(taxesList = true), getAllTaxes()">
        </b-tab>
      </b-tabs>
    </b-row> -->

      <b-row class="justify-content-between justify-item-center d-flex p-1">
        <b-col cols="12">
          <b-alert show variant="success">
            <p class="text-start ml-1 pt-2"><strong>INFORMATIVO</strong></p>
            <hr />
            <p class="text-start p-1">
              Este cálculo é composto da seguinte forma: <br /><br />
              ✅ Calcular o Fator R (Folha de Pagamento ÷ Receita Bruta); <br />
              ✅ Definir o Anexo (Anexo III se ≥ 28% Anexo V se menor que
              28%);<br />
              ✅ Usar a Tabela do Anexo para calcular a Alíquota Efetiva; <br />
              ✅ Multiplicar pela Receita Bruta do Mês para saber o imposto
              devido.
            </p>
            <br />
          </b-alert>
        </b-col>
        <b-col cols="12">
          <b-alert show variant="warning">
            <p class="text-center p-1">
              <br />
              Ajuste as <strong>Receitas</strong> e os
              <strong>Custos com pessoal</strong> para calcular a alíquota
              percentual de imposto.
            </p>
            <br />
          </b-alert>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Receita bruta dos 12 últimos meses (RBT)</label>
            <b-form-input
              type="text"
              v-model="simplesNacional.bruteRevenue"
              v-money="moneyMask"
              placeholder="R$ 0,00"
              class="mb-2"
            ></b-form-input>

            <label>Receita do mês atual</label>
            <b-form-input
              type="text"
              v-model="simplesNacional.actualMonthRevenue"
              v-money="moneyMask"
              placeholder="R$ 0,00"
              class="mb-2"
            ></b-form-input>

            <label>Folha de Pagamento dos 12 últimos meses </label>
            <b-form-input
              type="text"
              v-model="simplesNacional.payroll"
              v-money="moneyMask"
              placeholder="R$ 0,00"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-3">
          <b-button v-if="tableTaxes.length == 0" variant="primary" @click="saveTaxes"> Calcular </b-button>
          <b-button v-else variant="primary" @click="updateTaxes"> Atualizar impostos </b-button>

        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="tableTaxes.length > 0" title="Impostos">
      <b-alert show variant="info">
        <p class="text-start ml-1 pt-2"><strong>RESULTADOS</strong></p>
        <hr />
        <p class="text-center p-1">
          Baseando-se na receita nos útlimos 12 meses mais o mês atual O valor
          do DAS mensal é de <strong> {{ numberToMonetary(dasValue) }}</strong
          >. O FATOR R foi de
          <strong>{{ (r_factor * 100).toFixed(2) }}%</strong> conforme informado
          na Folha de Pagamento dos 12 últimos meses. <br />
          O enquandramento é:
          <strong>{{ allSimplesNacionalValues[0].tax_name }}</strong> sobre
          alíquota efetiva de
          {{ (allSimplesNacionalValues[0].tax_percentage / 100).toFixed(2) }}%
        </p>
        <br />
      </b-alert>
      <b-table
        responsive="sm"
        small
        :items="tableTaxes"
        :fields="fields"
        class="p-1 w-100 table-details"
        show-empty
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              Não existem <strong>Impostos registrados.</strong>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(tax_percentage)="{ item }">
          {{ `${item.tax_percentage}%` }}
        </template>
      </b-table>
    </b-card>
    <b-card title="Disclaimer">
      <b-row>
        <b-col cols="12">
          <b-alert show variant="danger">
            <p class="text-start ml-1 pt-2"><strong>ATENÇÃO</strong></p>
            <hr />
            <p class="text-center p-1">
              Este cálculo não substitui a necessidade de um contador! Valores e
              cálculos são passíveis de variação ou erro humano. Sempre consulte
              seu contador!
            </p>
            <br />
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BCol,
  BAlert,
  BTable,
  // BIconTrash,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAlert,
    BTable,
  },
  data: () => ({
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
    editable: false,
    itemUpdate: null,
    search: '',
    dasValue: null,
    r_factor: null,
    allSimplesNacionalValues: null,
    tableTaxes: [],
    simplesNacional: {
      bruteRevenue: '',
      actualMonthRevenue: '',
      payroll: '',
    },
    start_date: moment()
      .subtract(12, 'months')
      .startOf('month')
      .format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
    fields: [
      {
        key: 'tax_name',
        label: 'Nome',
        sortable: true,
      },
      {
        key: 'tax_percentage',
        label: 'Aliquota efetiva',
        sortable: true,
      },
    ],
  }),

  async created() {
    await this.getAllRevenues();
    await this.getTaxes();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    filter() {
      if (this.search.trim() === '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          return (
            item.tax_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },
    handleUpdateList() {
      this.getAllTaxes();
    },
    getTaxes() {
      let body = {
        workspace_id: this.currentWorkspace.id,
      };
      this.$store.dispatch('getAllTaxes', body).then((resp) => {
        const excludeTaxes = ['Valor do DAS', 'Valor do fator R'];

        if (resp) {
          this.tableTaxes = resp.filter(
            (tax) =>
              !excludeTaxes.includes(tax.tax_name) &&
              !tax.tax_name.startsWith('Simples Nacional')
          );

          const dasTax = resp.find((tax) => tax.tax_name === 'Valor do DAS');
          const r_factor = resp.find(
            (tax) => tax.tax_name === 'Valor do fator R'
          );

          const allSimplesNacionalTaxes = resp.filter((tax) =>
            tax.tax_name.startsWith('Simples Nacional')
          );

          this.r_factor = r_factor ? r_factor.tax_percentage : 0;
          this.dasValue = dasTax ? dasTax.tax_percentage : 0;
          this.allSimplesNacionalValues = allSimplesNacionalTaxes.map(
            (tax) => ({
              tax_name: tax.tax_name,
              tax_percentage: tax.tax_percentage,
            })
          );
        }
      });
    },
    async saveTaxes() {
      let body = {
        workspace_id: this.currentWorkspace.id,
        bruteRevenue: this.monetaryToNumber(this.simplesNacional.bruteRevenue),
        actualMonthRevenue: this.monetaryToNumber(
          this.simplesNacional.actualMonthRevenue
        ),
        payroll: this.monetaryToNumber(this.simplesNacional.payroll),
      };

      this.$store
        .dispatch('saveTaxes', body)
        .then((resp) => {
          this.loading = false;
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Impostos cadastrados com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
           this.getTaxes();
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao cadastrar imposto',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    async updateTaxes() {
      let body = {
        workspace_id: this.currentWorkspace.id,
        bruteRevenue: this.monetaryToNumber(this.simplesNacional.bruteRevenue),
        actualMonthRevenue: this.monetaryToNumber(this.simplesNacional.actualMonthRevenue),
        payroll: this.monetaryToNumber(this.simplesNacional.payroll),
      };

      this.$store
        .dispatch('updateTaxes', body)
        .then((resp) => {
          this.loading = false;
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Impostos atualizados com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
           this.getTaxes();
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao cadastrar imposto',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    async getAllRevenues() {
      let body = {
        workspace_id: this.$store.getters.currentWorkspace.id,
        archived: false,
        page: this.currentPage,
        perPage: 100000,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store
        .dispatch('getAllRevenues', body)
        .then((resp) => {
          if (resp) {
            console.log(resp);
            this.simplesNacional.bruteRevenue = this.numberToMonetary(
              resp.totals.totalGeral
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
